import React from "react";
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import SectionTitle from '../../components/sectiontitle.jsx';
import Section from '../../components/section.jsx';

// import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

// import NS1 from '../../images/portfolio/neoscroll/neoscroll-1.png';
// import NS2 from '../../images/portfolio/neoscroll/neoscroll-2.png';
// import NS3 from '../../images/portfolio/neoscroll/neoscroll-3.png';
// import NS4 from '../../images/portfolio/neoscroll/neoscroll-4.png';


function PortfolioWildcardpeople() {
  return (
    <Layout currentPage={[{title:"Portfolio", url:"portfolio"}, {title:"Wildcard People", url:"/portfolio/wildcardpeople"}]}>
      <SEO
        keywords={[`Robert Merki`, `Robmerki`, `Rob Merki`, `@robmerki`,`Merki.ca`]}
        title="Wildcard People"
      />

      
      <Section>
        <SectionTitle title="TL;DR" />
        <p>
          I made a website for neurodiverse tech generalists (aka <a className="underline" href="https://wildcardpeople.com/what-is-a-wildcard-person" target="_blank" rel="noreferrer" noopen="true">Wildcard People</a>) to find startup jobs and it blew up.
        </p>

        <SectionTitle title="The Idea" />
        <p>
          I have 64,000 followers <a href="https://www.tiktok.com/@adhdpro.xyz" target="_blank" rel="noreferrer" noopen="true">on TikTok</a>. 
          Initially I just wanted to post practical advice about ADHD and build an audience to promote my book on Amazon.
        </p>
        <p>
          After speaking with a friend of mine, we came to the conclusion that people with ADHD generally end up being "generalists" or "jack of all trades" at their jobs.
          We thrive with novelty and suffer from repetition. This sounds like a lot of people, but we do this to the extreme.
        </p>
        <p>
          Because we dive into so many things, our long list of skills sounds like an overzealous highschool student's blatantly false resume. Also, these people do great at startups.
        </p>
        <p>
          My friend suggested the label "Wildcard", and it stuck.
        </p>
        <p>
          (Check out the full description <a className="font-semibold underline" href="https://wildcardpeople.com/what-is-a-wildcard-person" target="_blank" rel="noreferrer" noopen="true">here</a>.)
        </p>


        <SectionTitle title="Testing the Waters" />
        <p>
          First, I posted a TikTok video asking for feedback for the "Wildcard" description. Most comments said something like: "you're describing me!"
        </p>
        <p>
          Second, I built a landing page and two Google Forms: one for those looking to be hired as a Wildcard, 
          the other for employers who wanted to hire a Wildcard.
        </p>
        <p>
          I hoped for a few signups, but it blew up.
        </p>
        
        <h3 className="font-bold underline">Stats after 72 hours:</h3>
        <dl className="grid sm:grid-cols-5 mb-6 bg-blue-50 px-5 py-4">
          <dt className="col-span-2 font-semibold">Tiktok views</dt>
          <dd className="col-span-3 mb-4 sm:mb-0">110,000</dd>

          <dt className="col-span-2 font-semibold">Tiktok comments</dt>
          <dd className="col-span-3 mb-4 sm:mb-0">2,000</dd>
          
          <dt className="col-span-2 font-semibold">Landing page visits</dt>
          <dd className="col-span-3 mb-4 sm:mb-0">6,000</dd>
          
          <dt className="col-span-2 font-semibold">Wildcard signups</dt>
          <dd className="col-span-3 mb-4 sm:mb-0">1,000</dd>
          
          <dt className="col-span-2 font-semibold">Employer signups</dt>
          <dd className="col-span-3 mb-4 sm:mb-0">20</dd>

          <dt className="col-span-2 font-semibold">Tags on LinkedIn</dt>
          <dd className="col-span-3 mb-4 sm:mb-0">7</dd>

          <dt className="col-span-2 font-semibold">Rank on Google</dt>
          <dd className="col-span-3 mb-4 sm:mb-0">1</dd>

          <dt className="col-span-2 font-semibold">Wildcards hired</dt>
          <dd className="col-span-3">1</dd>
        </dl>
        
        <p>
          I only posted those two Tiktok videos. No other promotion. Clearly I've found an untapped market.
        </p>

        <p>
          My current process is inefficient, but it's working and people are getting hired.
          I'm manually finding candidates based on their intake forms and matching them to companies.
        </p>

        <p>
          This all fell into place rather quickly, but I'm helping people thrive <em>and</em> finding great hires for startups. It feels great.
        </p>

        <p>
          Want to join the fun? Take a look: <a className="font-semibold underline" href="https://wildcardpeople.com/" target="_blank" rel="noreferrer" noopen="true">WildcardPeople.com</a>
        </p>

      </Section>
      
    </Layout>
  );
}


export default PortfolioWildcardpeople;